import React, { useContext, useEffect, useState } from 'react'
import { Container, Grid, IconButton, Paper, Typography } from '@mui/material'
import InputText from '../../DevComponents/InputText'
import { ContextoGlobal, ContextoGlobalInterface } from '../../GlobalStates/ContextoGlobal'
import Condicional from '../../Layout/Condicional'
import Button from '@mui/material/Button'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import HelpIcon from '@mui/icons-material/Help'

import DataTable, { DataTableCabecalhoInterface } from '../../DevComponents/DataTable'
import { useNavigate } from 'react-router-dom'
import { ClinicaInstanciaWhatsAPPInterface, ClinicaInterface } from '../../ImportBackend/Interfaces/ClinicaInterfaces'
import ClsCrud from '../../Utils/ClsCrud'

import ClsValidacao from '../../Utils/ClsValidacao'
// import PesquisarTabela from '../../DevComponents/PesquisarTabela'
import { EMDESENVOLVIMENTO } from '../../ImportBackend/Config/emDesenvolvimento'
import PesquisarTabela from '../../DevComponents/PesquisarTabela'
import ClinicasFeriados from './ClinicasFeriados'
import ClsAcesso from '../../Utils/ClsAcesso'
import { SISTEMA_PERMISSOES } from '../../ImportBackend/types/AcessosDataTypes'
import TextArea from '../../DevComponents/TextArea'
import ComboBox from '../../DevComponents/ComboBox'
import BackEndAPI from '../../Services/BackEndAPI'
import ClinicasHelp from './ClinicasHelp'

interface PesquisaInterface {
  descricao: string
}

export enum StatusForm {
  Incluindo,
  Excluindo,
  Pesquisando,
  Editando,
  EditandoFeriados
}

export default function Clinicas () {

  const [statusForm, setStatusForm] = useState<StatusForm>( StatusForm.Pesquisando )

  const Cabecalho: Array<DataTableCabecalhoInterface> = [
    // idClinica idEcuroClinica ativa descricao cidade latitude longitude whatsAPP linkAvaliacao
    {
      campo: 'descricao',
      cabecalho: 'Descrição',
      alinhamento: 'left'
    },
    {
      campo: 'cidade',
      cabecalho: 'Cidade',
      alinhamento: 'left'
    },
    {
      campo: 'whatsAPP',
      cabecalho: 'WhatsApp',
      alinhamento: 'left'
    },
    {
      campo: 'inicioAgSegSex',
      cabecalho: 'Início Seg-Sex',
      alinhamento: 'left'
    },
    {
      campo: 'terminoAgSegSex',
      cabecalho: 'Término Seg-Sex',
      alinhamento: 'left'
    },
    {
      campo: 'inicioAgSab',
      cabecalho: 'Início Sábado',
      alinhamento: 'left'
    },
    {
      campo: 'terminoAgSab',
      cabecalho: 'Término Sábado',
      alinhamento: 'left'
    },
    {
      campo: 'ativa',
      cabecalho: 'Ativa',
      alinhamento: 'left',
      format: ( v ) => v ? 'Sim' : 'Não'
    }
  ]

  const ResetDados: ClinicaInterface = {
    idClinica: 0,
    idEcuroClinica: '',
    ativa: false,
    descricao: '',
    cidade: '',
    latitude: '0',
    longitude: '0',
    whatsAPP: '',
    linkAvaliacao: '',
    inicioAgSegSex: '',
    terminoAgSegSex: '',
    inicioAgSab: '',
    terminoAgSab: '',
    enviarMensagemDiaAnteriorConsulta: false,
    enviarMensagemDiaConsulta: false,
    mensagemDiaAnteriorConsulta: '',
    mensagemDiaConsulta: '',
    waInstancia: ''
  }

  const TituloForm = {
    [StatusForm.Incluindo]: 'Inclusão de Nova Clínica',
    [StatusForm.Excluindo]: 'Exclusão de Clínica Não Utilizada',
    [StatusForm.Pesquisando]: 'Clínicas disponívels no APP',
    [StatusForm.Editando]: 'Alteração de Dados de Clínicas',
    [StatusForm.EditandoFeriados]: 'Feriados da Clínica',
  }

  const clsApi = new BackEndAPI()
  const abortController: AbortController = new AbortController()

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface
  const { mensagemState, setMensagemState } = contexto

  const [dados, setDados] = useState<ClinicaInterface>( ResetDados )
  const [rsClinicaInstanciaWhatsAPP, setRsClinicaInstanciaWhatsAPP] = useState( Array<ClinicaInstanciaWhatsAPPInterface> )
  const contextoGlobal = useContext( ContextoGlobal ) as ContextoGlobalInterface

  const [erros, setErros] = useState( {} )

  const [pesquisa, setPesquisa] = useState<PesquisaInterface>( { descricao: '' } )

  const [rsPesquisa, setRsPesquisa] = useState<Array<ClinicaInterface>>( [] )

  const [exibirClinicasHelp, setExibirClinicasHelp] = useState<boolean>( false )

  const navigate = useNavigate()

  const validarDados = (): boolean => {

    let retorno: boolean = true
    let retornoSegSex: boolean = true
    let retornoSabado: boolean = true
    let erros: { [key: string]: string } = {}

    let clsValidacao = new ClsValidacao()

    if ( dados.enviarMensagemDiaConsulta ) {
      retorno = clsValidacao.naoVazio( 'mensagemDiaConsulta', dados, erros, retorno )
    }

    if ( dados.enviarMensagemDiaAnteriorConsulta ) {
      retorno = clsValidacao.naoVazio( 'mensagemDiaAnteriorConsulta', dados, erros, retorno )
    }

    if ( dados.enviarMensagemDiaAnteriorConsulta || dados.enviarMensagemDiaConsulta ) {
      retorno = clsValidacao.naoVazio( 'waInstancia', dados, erros, retorno )
    }

    retorno = clsValidacao.naoVazio( 'descricao', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'cidade', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'idEcuroClinica', dados, erros, retorno )
    retorno = clsValidacao.eLatitude( 'latitude', dados, erros, retorno )
    retorno = clsValidacao.eLongitude( 'longitude', dados, erros, retorno )
    retorno = clsValidacao.eTelefone( 'whatsAPP', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'linkAvaliacao', dados, erros, retorno )
    retornoSegSex = clsValidacao.eHorario( 'inicioAgSegSex', dados, erros, retornoSegSex )
    retornoSegSex = clsValidacao.eHorario( 'terminoAgSegSex', dados, erros, retornoSegSex )
    retornoSabado = clsValidacao.eHorario( 'inicioAgSab', dados, erros, retornoSabado )
    retornoSabado = clsValidacao.eHorario( 'terminoAgSab', dados, erros, retornoSabado )

    retorno = retorno && retornoSabado && retornoSegSex

    if ( retornoSabado ) {
      if ( dados.inicioAgSab > dados.terminoAgSab ) {
        erros.inicioAgSab = 'Horário Deve Ser Menor que Término'
        retorno = false
      }
    }

    if ( retornoSegSex ) {
      if ( dados.inicioAgSegSex > dados.terminoAgSegSex ) {
        erros.inicioAgSegSex = 'Horário Deve Ser Menor que Término'
        retorno = false
      }

    }

    setErros( erros )

    return retorno

  }

  const clsCrud: ClsCrud<ClinicaInterface> = new ClsCrud(
    navigate,
    ResetDados,
    setStatusForm as unknown as any,
    setDados,
    setErros,
    mensagemState,
    setMensagemState,
    setRsPesquisa,
    contexto,
    validarDados,
    {
      confirmarMutation: 'updateClinica',
      excluirMutation: 'delClinica',
      campoId: 'idClinica',
      camposPesquisa: '{idClinica ativa descricao cidade whatsAPP inicioAgSegSex terminoAgSegSex inicioAgSab terminoAgSab}',
      pesquisaQuery: 'getClinicas',
      pesquisaPorId: 'getClinicaPorId',
      camposPesquisaPorId: '{idClinica idEcuroClinica ativa descricao cidade latitude longitude whatsAPP linkAvaliacao inicioAgSegSex terminoAgSegSex inicioAgSab terminoAgSab enviarMensagemDiaAnteriorConsulta enviarMensagemDiaConsulta mensagemDiaAnteriorConsulta mensagemDiaConsulta waInstancia}'
    },
    {
      confirmando: 'Atualizando Clínica',
      erroCadastro: 'Erro ao Cadastrar Clínica',
      erroExclusao: 'Erro ao Excluir Clínica',
      erroPesquisa: 'Erro ao Pesquisar Clínica',
      pesquisando: 'Pesquisando Dados de Clínicas...',
      sucessoCadastro: 'Clínica Cadastrada com Sucesso!',
      atualizacaoSucesso: 'Clínica Atualizada com Sucesso!',
      tituloConfirmado: 'Confirmado!',
      sucessoExclusao: 'Clínica Excluída com Sucesso...',
      tituloConfirmacaoExclusao: 'Confirma?',
      tituloErroCadastro: 'Erro!',
      tituloErroExclusao: 'Erro!',
      tituloErroPesquisa: 'Erro!',
      excluindo: 'Excluindo Clínica...'
    }
  )


  const onKeyPesquisa = () => {
    clsCrud.onClickPesquisa( pesquisa.descricao, mensagemState )
  }

  const btFeriado = ( rs: ClinicaInterface ) => {
    setDados( rs )
    setStatusForm( StatusForm.EditandoFeriados )
  }

  const clsAcesso: ClsAcesso = new ClsAcesso()

  const btCancelarFeriado = () => {
    setStatusForm( StatusForm.Pesquisando )
  }

  // Pesquisar Instancias de WhatsAPP

  const pesquisarInstanciasWhatsAPP = () => {

    const query: string = `
      getClinicaInstanciaWhatsAPP {
        waInstancia
      }
    `

    clsApi.query<Array<ClinicaInstanciaWhatsAPPInterface>>( query, 'getClinicaInstanciaWhatsAPP', 'Pesquisando Instâncias de WhatsAPP...', contextoGlobal, abortController ).then( rs => {

      setRsClinicaInstanciaWhatsAPP( rs )

    } )

    return () => {

      abortController.abort()

    }

  }


  // let acoesPermitidas: Array<{ icone: string, toolTip: string, onAcionador: ( v: any ) => void }> = []

  const [rsAcoesPermitidas, setRsAcoesPermitidas] = useState<Array<{ icone: string, toolTip: string, onAcionador: ( v: any ) => void }>>( [] )

  const checarAcoesPermitidas = () => {
    const permiteFeriado = clsAcesso.chkAcesso( contexto.loginState.permissoes, SISTEMA_PERMISSOES.CLINICAS.MODULO, SISTEMA_PERMISSOES.CLINICAS.PERMISSOES.FERIADOS )
    const permiteClinica = clsAcesso.chkAcesso( contexto.loginState.permissoes, SISTEMA_PERMISSOES.CLINICAS.MODULO, SISTEMA_PERMISSOES.CLINICAS.PERMISSOES.MANUTENCAO )

    let acoesFeriado = permiteFeriado ? [{ icone: 'calendar_today', toolTip: 'Feriados', onAcionador: btFeriado }] : []
    let acoesClinica = permiteClinica ? [
      { icone: 'create', toolTip: 'Editar', onAcionador: clsCrud.btEditar },
      { icone: 'delete', toolTip: 'Exluir', onAcionador: clsCrud.btExcluir }
    ] : []

    setRsAcoesPermitidas( acoesClinica.concat( acoesFeriado ) )

  }

  useEffect( () => {
    checarAcoesPermitidas()
    pesquisarInstanciasWhatsAPP()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [] )

  return (
    <>
      <Container maxWidth="lg" sx={{ mt: 5 }}>

        <ClinicasHelp exibir={exibirClinicasHelp} setExibir={setExibirClinicasHelp} />

        <Paper variant="outlined" sx={{ padding: 2 }}>
          <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
              <Typography component="h5" variant="h5" align="left">
                Cadastro de Clínicas
                <Typography variant="body2" gutterBottom>
                  {TituloForm[statusForm]}
                </Typography>
              </Typography>

              <IconButton onClick={() => clsCrud.btFechar()}>
                <CloseIcon />
              </IconButton>

            </Grid>

            <Condicional condicao={statusForm === StatusForm.Pesquisando}>

              <Grid item xs={12} sm={10} >

                <InputText
                  dados={pesquisa}
                  field='descricao'
                  label='Pesquisar'
                  setState={setPesquisa}
                  iconeEnd="search"
                  onClickIconeEnd={() => clsCrud.onClickPesquisa( pesquisa.descricao, mensagemState )}
                  mapKeyPress={[{ key: 'Enter', onKey: onKeyPesquisa }]}
                />

              </Grid>

              <Grid item xs={12} sm={2} sx={{ mt: { xs: 3, sm: 4.5 }, textAlign: { xs: 'right', sm: 'center' } }}>
                <Button variant='contained' onClick={
                  () => {
                    clsCrud.btIncluir()
                  }
                }>Incluir</Button>
              </Grid>

            </Condicional>

            <Condicional condicao={statusForm !== StatusForm.Pesquisando && statusForm !== StatusForm.EditandoFeriados}>

              <Grid item xs={12}>

                <PesquisarTabela<any>
                  setState={setDados}
                  field='idEcuroClinica'
                  fieldSet='idEcuroClinica'
                  label='Ecuro / Clínica'
                  dados={dados}
                  campoQueryPesquisaID='idEcuroClinica'
                  campoQueryPesquisa='pesquisa'
                  camposRetornoQueryPesquisa='{idEcuroClinica, descricao}'
                  campoLabelQueryPesquisa='descricao'
                  nomeQueryPesquisa='getEcuroClinicas'
                  nomeQueryPesquisaID='getEcuroClinicaPorId'
                  mensagemPesquisa='Procurando Clínicas do Ecuro...'
                  disabled={statusForm === StatusForm.Excluindo}
                  erros={erros}
                  pesquisarTudoAoIniciar
                />

              </Grid>

              <Grid item xs={12}>

                <InputText
                  dados={dados}
                  field='descricao'
                  label='Descrição'
                  setState={setDados}
                  disabled={statusForm === StatusForm.Excluindo}
                  erros={erros}
                  maxLength={50}
                />

              </Grid>

              <Grid item xs={12} sm={8}>

                <InputText
                  dados={dados}
                  field='cidade'
                  label='Cidade'
                  setState={setDados}
                  disabled={statusForm === StatusForm.Excluindo}
                  erros={erros}
                  maxLength={40}
                />

              </Grid>

              <Grid item xs={12} sm={4} sx={{ pl: { sm: 1 } }}>

                <InputText
                  dados={dados}
                  field='whatsAPP'
                  label='Whats APP'
                  setState={setDados}
                  disabled={statusForm === StatusForm.Excluindo}
                  erros={erros}
                  mask='tel'
                />

              </Grid>

              <Grid item xs={12} sm={6}>

                <InputText
                  dados={dados}
                  field='linkAvaliacao'
                  label='Link de Avaliação'
                  setState={setDados}
                  disabled={statusForm === StatusForm.Excluindo}
                  erros={erros}
                  maxLength={255}
                />

              </Grid>

              <Grid item xs={12} sm={3} sx={{ pl: { sm: 1 } }}>

                <InputText
                  dados={dados}
                  field='latitude'
                  label='Latitude'
                  setState={setDados}
                  disabled={statusForm === StatusForm.Excluindo}
                  erros={erros}
                />

              </Grid>

              <Grid item xs={12} sm={3} sx={{ pl: { sm: 1 } }}>
                <InputText
                  dados={dados}
                  field='longitude'
                  label='Longitude'
                  setState={setDados}
                  disabled={statusForm === StatusForm.Excluindo}
                  erros={erros}
                />
              </Grid>

              <Grid item xs={6} sm={3}>

                <InputText
                  dados={dados}
                  field='inicioAgSegSex'
                  label='Início Seg-Sex'
                  setState={setDados}
                  disabled={statusForm === StatusForm.Excluindo}
                  erros={erros}
                  maxLength={5}
                  mask='00:00'
                />

              </Grid>

              <Grid item xs={6} sm={3} sx={{ pl: 1 }}>

                <InputText
                  dados={dados}
                  field='terminoAgSegSex'
                  label='Término Seg-Sex'
                  setState={setDados}
                  disabled={statusForm === StatusForm.Excluindo}
                  erros={erros}
                  maxLength={5}
                  mask='00:00'
                />

              </Grid>

              <Grid item xs={6} sm={3} sx={{ pl: { sm: 1 } }}>

                <InputText
                  dados={dados}
                  field='inicioAgSab'
                  label='Início Sábado'
                  setState={setDados}
                  disabled={statusForm === StatusForm.Excluindo}
                  erros={erros}
                  maxLength={5}
                  mask='00:00'
                />

              </Grid>

              <Grid item xs={6} sm={3} sx={{ pl: 1 }}>

                <InputText
                  dados={dados}
                  field='terminoAgSab'
                  label='Término Sábado'
                  setState={setDados}
                  disabled={statusForm === StatusForm.Excluindo}
                  erros={erros}
                  maxLength={5}
                  mask='00:00'
                />

              </Grid>

              <Grid item xs={12} sx={{ mt: 4 }}>
                <InputText
                  dados={dados}
                  field='ativa'
                  label='Ativa'
                  setState={setDados}
                  tipo='checkbox'
                  disabled={statusForm === StatusForm.Excluindo}
                  erros={erros}
                />
              </Grid>

              <Grid item xs={11} sx={{ mt: 3 }}>

                <Typography variant="h6" gutterBottom>
                  Setup do WhatsAPP da Clínica
                </Typography>
              </Grid>

              <Grid item xs={1} sx={{ mt: 3, textAlign: 'right' }}>
                <IconButton aria-label="delete" onClick={() => setExibirClinicasHelp( true )}>
                  <HelpIcon fontSize="inherit" />
                </IconButton>

              </Grid>

              <Grid item xs={12}>
                <ComboBox
                  campoDescricao='waInstancia'
                  campoID='waInstancia'
                  opcoes={rsClinicaInstanciaWhatsAPP}
                  setState={setDados}
                  dados={dados}
                  field='waInstancia'
                  label='Instância'
                  mensagemPadraoCampoEmBranco='Escolha a Instância do WhatsAPP'
                  erros={erros}
                />
              </Grid>

              <Grid item xs={12} sx={{ mt: 2 }} >
                <InputText
                  field='enviarMensagemDiaConsulta'
                  label='Enviar mensagem no dia da consulta'
                  dados={dados}
                  setState={setDados}
                  erros={erros}
                  tipo='checkbox'
                />
              </Grid>

              <Grid item xs={12} >

                <TextArea
                  dados={dados}
                  field='mensagemDiaConsulta'
                  label='Mensagem Para o Dia da Consulta'
                  setState={setDados}
                  height='120px'
                  erros={erros}
                />

              </Grid>

              <Grid item xs={12} sx={{ mt: 2 }} >
                <InputText
                  field='enviarMensagemDiaAnteriorConsulta'
                  label='Enviar mensagem no dia anterior a consulta'
                  dados={dados}
                  setState={setDados}
                  erros={erros}
                  tipo='checkbox'
                />
              </Grid>

              <Grid item xs={12} >

                <TextArea
                  dados={dados}
                  field='mensagemDiaAnteriorConsulta'
                  label='Mensagem Para o Dia Anterior a Consulta'
                  setState={setDados}
                  height='120px'
                  erros={erros}
                />

              </Grid>

              <Grid item xs={12} sx={{ mt: 3 }}>

                <Condicional condicao={statusForm === StatusForm.Excluindo}>
                  <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => clsCrud.btConfirmarExclusao( dados, mensagemState, pesquisa.descricao )}>Confirmar</Button>
                </Condicional>

                <Condicional condicao={statusForm !== StatusForm.Excluindo}>
                  <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => clsCrud.btConfirmar( dados, mensagemState, statusForm as unknown as any, pesquisa.descricao )}>Confirmar</Button>
                </Condicional>

                <Button variant='contained' startIcon={<CloseIcon />} sx={{ py: 1 }} onClick={() => clsCrud.btCancelar()}>Cancelar</Button>

              </Grid>

            </Condicional>

            <Condicional condicao={statusForm === StatusForm.Pesquisando}>
              <Grid item xs={12} sx={{ mt: 3 }}>
                <DataTable dados={rsPesquisa} cabecalho={Cabecalho} acoes={rsAcoesPermitidas} />
              </Grid>
            </Condicional>

            <ClinicasFeriados
              statusForm={statusForm as unknown as any}
              dados={dados}
              onCancelar={btCancelarFeriado}
            />

            <Condicional condicao={EMDESENVOLVIMENTO && false}>
              <Grid item xs={12}>
                <pre >
                  {JSON.stringify( dados, null, 4 )}
                </pre>
              </Grid>
            </Condicional>

          </Grid>
        </Paper>

      </Container>
    </>
  )
}