import React, { useContext, useEffect, useState } from 'react'
import { ContextoGlobal, ContextoGlobalInterface } from '../../GlobalStates/ContextoGlobal'
import BackEndAPI from '../../Services/BackEndAPI'
import { Button, Container, Grid, IconButton, Paper, Typography } from '@mui/material'
import InputText from '../../DevComponents/InputText'
import { EMDESENVOLVIMENTO } from '../../ImportBackend/Config/emDesenvolvimento'
import { useNavigate } from 'react-router-dom'

import CloseIcon from '@mui/icons-material/Close'
import Condicional from '../../Layout/Condicional'
import { ApiWhatsAppResponseInterface, ApiWhatsAppStatusInterface, StatusSessao } from '../../ImportApiWhatsAPP/tipos/dataTypes'
import ExibirJSONDev from '../../DevComponents/ExibirJSONDev'
import QrCodeView from './QrCodeView'
import { ClinicaInstanciaWhatsAPPInterface } from '../../ImportBackend/Interfaces/ClinicaInterfaces'
import ComboBox from '../../DevComponents/ComboBox'

export default function SetupWhatsAPP () {

  const [dados, setDados] = useState<ApiWhatsAppStatusInterface>( {
    ok: false,
    status: StatusSessao.cadastroInicial,
    mensagem: '',
    tempoMinStatus: 0,
    urlCode: ''
  } )

  const [rsClinicaInstanciaWhatsAPP, setRsClinicaInstanciaWhatsAPP] = useState( Array<ClinicaInstanciaWhatsAPPInterface> )
  const [dadosInstancia, setDadosInstancia] = useState<ClinicaInstanciaWhatsAPPInterface>( { waInstancia: '' } )

  const clsApi = new BackEndAPI()
  const abortController: AbortController = new AbortController()
  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface

  const pesquisarInstanciasWhatsAPP = () => {

    const query: string = `
      getClinicaInstanciaWhatsAPP {
        waInstancia
      }
    `

    clsApi.query<Array<ClinicaInstanciaWhatsAPPInterface>>( query, 'getClinicaInstanciaWhatsAPP', 'Pesquisando Instâncias de WhatsAPP...', contexto, abortController ).then( rs => {

      setRsClinicaInstanciaWhatsAPP( rs )

    } )

    return () => {

      abortController.abort()

    }

  }

  const navigate = useNavigate()

  const getStatus = () => {

    if ( dadosInstancia.waInstancia.length > 0 ) {

      const query: string = `
        statusApiWhatsAPP (waInstancia: "${dadosInstancia.waInstancia}") {
          ok
          status
          mensagem
          tempoMinStatus
          urlCode
        }
      `

      clsApi.mutation<ApiWhatsAppStatusInterface>( query, 'statusApiWhatsAPP', 'Recebendo Status', contexto ).then( rs => {

        setDados( rs )

      } )

    }

  }

  useEffect( () => {
    pesquisarInstanciasWhatsAPP()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [] )

  useEffect( () => {

    getStatus()

    const timeoutId = setInterval( () => {
      getStatus()
    }, 19000 );

    return () => clearInterval( timeoutId )

    //eslint-disable-next-line
  }, [dadosInstancia.waInstancia] )

  const iniciarWhatsAPP = () => {

    if ( dadosInstancia.waInstancia.length > 0 ) {

      const query: string = `
        iniciarWhatsAPP (waInstancia: "${dadosInstancia.waInstancia}") {
          ok
          status
          mensagem
        }
      `

      clsApi.mutation<ApiWhatsAppResponseInterface>( query, 'iniciarWhatsAPP', 'Iniciando...', contexto ).then( rs => {

        setDados( { ...rs, tempoMinStatus: 0, urlCode: '' } )
        getStatus()

      } )
    }

  }

  const finalizarWhatsAPP = () => {

    if ( dadosInstancia.waInstancia.length > 0 ) {
      const query: string = `
          finalizarInstancia (waInstancia: "${dadosInstancia.waInstancia}") {
            ok
            status
            mensagem
          }
        `

      clsApi.mutation<ApiWhatsAppResponseInterface>( query, 'finalizarInstancia', 'Finalizando...', contexto ).then( rs => {

        setDados( { ...rs, tempoMinStatus: 0, urlCode: '' } )
        getStatus()

      } )
    }

  }

  const onChangeWaInstancia = ( rs: ClinicaInstanciaWhatsAPPInterface ) => {

    if ( rs && rs.waInstancia.length > 0 ) {
      setDadosInstancia( rs )
    } else {
      setDadosInstancia( { waInstancia: '' } )
    }


  }

  return (
    <>

      <Container maxWidth="md" sx={{ mt: 5 }}>

        <Paper variant="outlined" sx={{ padding: 2 }}>
          <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
              <Typography component="h5" variant="h5" align="left">
                WhatsAPP
                <Typography variant="body2" gutterBottom>
                  Setup para Mensagens de WhatsAPP
                </Typography>
              </Typography>

              <IconButton onClick={() => navigate( '/' )}>
                <CloseIcon />
              </IconButton>
            </Grid>


            <Grid item xs={12}>
              <ComboBox
                campoDescricao='waInstancia'
                campoID='waInstancia'
                opcoes={rsClinicaInstanciaWhatsAPP}
                onChange={( rs: ClinicaInstanciaWhatsAPPInterface ) => onChangeWaInstancia( rs )}
                dados={dadosInstancia}
                field='waInstancia'
                label='Instância'
                mensagemPadraoCampoEmBranco='Escolha a Instância do WhatsAPP'
              />
            </Grid>

            <Condicional condicao={dadosInstancia.waInstancia.length > 0}>

              <Grid item xs={12}>
                <InputText
                  dados={dados}
                  field='mensagem'
                  label='Mensagem da API'
                  disabled
                />
              </Grid>

              <Grid item xs={12}>
                <InputText
                  dados={dados}
                  field='status'
                  label='Status da API'
                  disabled
                />
              </Grid>

              <Grid item xs={12}>
                <Button onClick={() => getStatus()}>Status</Button>
              </Grid>

              <Condicional condicao={![StatusSessao.iniciando, StatusSessao.conectada, StatusSessao.erro].includes( dados.status )}>
                <Grid item xs={12}>
                  <Button onClick={() => iniciarWhatsAPP()}>Conectar</Button>
                </Grid>
              </Condicional>

              <Condicional condicao={dados.status === StatusSessao.conectada || ( dados.status === StatusSessao.iniciando && dados.tempoMinStatus >= 3 )}>
                <Grid item xs={12}>
                  <Button onClick={() => finalizarWhatsAPP()}>Desconectar</Button>
                </Grid>
              </Condicional>


              <Condicional condicao={dados.status === StatusSessao.iniciando}>
                <QrCodeView status={dados.status} urlCode={dados.urlCode} />
              </Condicional>

            </Condicional>

          </Grid>
        </Paper>
      </Container>

      <ExibirJSONDev
        exibir={EMDESENVOLVIMENTO}
        oque={['Dados: ', dados]}
      />

    </>
  )

}