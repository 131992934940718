import React from 'react'
import { Button, Card, CardActionArea, CardActions, CardContent, Dialog, Typography } from '@mui/material'
import DataTable, { DataTableCabecalhoInterface } from '../../DevComponents/DataTable'

interface ModalComponentInterface {
  exibir: boolean
  setExibir: React.Dispatch<React.SetStateAction<any>>
}

export default function ClinicasHelp ( { exibir, setExibir }: ModalComponentInterface ) {

  const Cabecalho: Array<DataTableCabecalhoInterface> = [{
    campo: 'campo',
    cabecalho: 'Campo'
  }, {
    campo: 'descricao',
    cabecalho: 'Descrição'
  }]

  const rsDados = [
    {
      'campo': '[[PRIMEIRO_NOME]]',
      'descricao': 'Primeiro nome do Paciente'
    },
    {
      'campo': '[[CIDADE]]',
      'descricao': 'Cidade da Clínica'
    },
    {
      'campo': '[[ENDERECO]]',
      'descricao': 'Endereço da Clínica'
    },
    {
      'campo': ' [[LOCALIZACAO]]',
      'descricao': 'Localização (Google) da Clínica'
    },
    {
      'campo': '[[NOME_DENTISTA]]',
      'descricao': 'Nome do Dentista (Primeiro Nome)'
    },
    {
      'campo': '[[DIA]]',
      'descricao': 'Data da Consulta - dia/mes/ano'
    },
    {
      'campo': '[[HORA_CONSULTA]]',
      'descricao': 'Hora da Consulta'
    }
  ]

  return (
    <>
      <Dialog
        fullWidth
        open={exibir}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card >
          <CardActionArea>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Campos
              </Typography>
              <DataTable
                dados={rsDados}
                cabecalho={Cabecalho}
                acoes={[]} />
            </CardContent>
          </CardActionArea>
          <CardActions>
            <Button size="small" color="primary" onClick={() => setExibir( false )}>
              Fechar
            </Button>
          </CardActions>
        </Card>
      </Dialog>
    </>
  )

}