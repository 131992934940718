import React, { useContext, useEffect, useState } from 'react'
import { Box, Container, Grid, IconButton, Paper, Typography } from '@mui/material'
import InputText from '../../../DevComponents/InputText'
import { ContextoGlobal, ContextoGlobalInterface } from '../../../GlobalStates/ContextoGlobal'
import Condicional from '../../../Layout/Condicional'
import Button from '@mui/material/Button'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'

import DataTable, { DataTableAcaoInterface, DataTableCabecalhoInterface } from '../../../DevComponents/DataTable'
import { useNavigate } from 'react-router-dom'
import { ClienteInterface, ClientePesquisaInterface, rsPesquisaClienteInterface } from '../../../ImportBackend/Interfaces/ClienteInterfaces'
import ClsCrud from '../../../Utils/ClsCrud'

import ClsValidacao from '../../../Utils/ClsValidacao'
import BackEndAPI from '../../../Services/BackEndAPI'

import ExibirJSONDev from '../../../DevComponents/ExibirJSONDev'
import { clsUtils } from 'zlib-utils'
import { ClinicaInterface } from '../../../ImportBackend/Interfaces/ClinicaInterfaces'
import { PacienteEcuroInterface } from '../../../ImportBackend/Interfaces/EcuroInterfaces'
import { RespostaPadraoInterface } from '../../../ImportBackend/Interfaces/PadraoInterfaces'
import { MensagemTipo } from '../../../GlobalStates/MensagemState'
import ClienteAgendamentos from './ClienteAgendamentos'
import { EMDESENVOLVIMENTO } from '../../../ImportBackend/Config/emDesenvolvimento'
import ClsFormatacao, { DTFORMAT } from '../../../Utils/ClsFormatacao'
import { DateTime } from 'luxon'
import PesquisarTabela from '../../../DevComponents/PesquisarTabela'
import ClienteAgendamentoForm from './ClienteAgendamentoForm'
import { AgendamentoClienteCRMInputInterface } from '../../../ImportBackend/Interfaces/AgendamentoClienteInterfaces'
import { rsDisponibilidadeDentistaInterface } from '../../../ImportBackend/Interfaces/AgendamentoInterfaces'
import ClientesAvisoClienteExistenteModal from './ClientesAvisoClienteExistenteModal'

export enum StatusForm {
  Incluindo,
  Excluindo,
  Pesquisando,
  Editando,
  EditandoAgenda
}

interface rsSaldoInterface {
  saldo: number
  pagamentos: number
  aprovado: number
  executado: number
  oportunidades: number
}

export default function Clientes () {

  const clsFormatos: ClsFormatacao = new ClsFormatacao()

  const somarSaldos = ( rs: Array<any> ) => {

    let saldo = 0

    rs.forEach( ( v: any ) => {

      saldo = saldo + v.saldo

    } )

    return saldo

  }

  const [statusForm, setStatusForm] = useState<StatusForm>( StatusForm.Pesquisando )

  const CabecalhoAgrupamentoSaldo: Array<DataTableCabecalhoInterface> = [
    {
      campo: 'descricao',
      cabecalho: 'Especialidade',
      alinhamento: 'left'
    },
    {
      campo: 'saldo',
      cabecalho: 'Saldo',
      alinhamento: 'right',
      format: ( rs ) => clsFormatos.currency( rs )
    },
    {
      campo: 'pagamentos',
      cabecalho: 'Pagamentos',
      alinhamento: 'right',
      format: ( rs ) => clsFormatos.currency( rs )
    },
    {
      campo: 'aprovado',
      cabecalho: 'Aprovado',
      alinhamento: 'right',
      format: ( rs ) => clsFormatos.currency( rs )
    },
    {
      campo: 'executado',
      cabecalho: 'Executado',
      alinhamento: 'right',
      format: ( rs ) => clsFormatos.currency( rs )
    },
    {
      campo: 'oportunidades',
      cabecalho: 'Oportunidades',
      alinhamento: 'right',
      format: ( rs ) => clsFormatos.currency( rs )
    }
  ]

  const CabecalhoEcuro: Array<DataTableCabecalhoInterface> = [
    {
      campo: 'nome',
      cabecalho: 'Nome',
      alinhamento: 'left'
    },
    {
      campo: 'idEcuroClinica',
      cabecalho: 'Clínica',
      alinhamento: 'left',
      format: ( idEcuroClinica ) => rsClinicas.find( ( v: ClinicaInterface ) => v.idEcuroClinica === idEcuroClinica )?.descricao
    },
    {
      campo: 'idEcuroPublicId',
      cabecalho: 'Public ID',
      alinhamento: 'left'
    },
    /*
    {
      campo: 'idEcuroPaciente',
      cabecalho: 'ID Ecuro Paciente',
      alinhamento: 'left'
    },
    */
    {
      campo: 'cpf',
      cabecalho: 'CPF',
      alinhamento: 'left'
    },
    {
      campo: 'fone',
      cabecalho: 'Fone',
      alinhamento: 'left',
      format: ( rs: string ) => {

        if ( rs && rs.length > 0 ) {

          let fone = rs.replace( /^\+55/g, '' ).replace( /^\+/g, '' )
          const retorno = clsFormatos.telefone( fone ) as string
          // console.log( 'retorno', retorno, 'fone', fone )
          return retorno

        } else {
          return ''
        }

      }
    },
    {
      campo: 'dataNascimento',
      cabecalho: 'Data Nasc.',
      alinhamento: 'left'
    },
    {
      campo: 'saldo',
      cabecalho: 'Saldo',
      alinhamento: 'right',
      format: ( rs ) => clsFormatos.currency( somarSaldos( rs ) )
    },
  ]

  const CabecalhoAPP: Array<DataTableCabecalhoInterface> = [
    {
      campo: 'nome',
      cabecalho: 'Nome',
      alinhamento: 'left'
    },
    {
      campo: 'cpf',
      cabecalho: 'CPF',
      alinhamento: 'left'
    },
    {
      campo: 'Clinica',
      cabecalho: 'Clínica',
      alinhamento: 'left',
      format: ( v ) => v.descricao
    },
    {
      campo: 'whatsAPP',
      cabecalho: 'Whats APP',
      alinhamento: 'left'
    },
    {
      campo: 'fone',
      cabecalho: 'Fone (Chave Ecuro)',
      alinhamento: 'left'
    },
    {
      campo: 'dataNascimento',
      cabecalho: 'Data Nasc.',
      alinhamento: 'left'
    },
    {
      campo: 'email',
      cabecalho: 'e-mail',
      alinhamento: 'left'
    },
    {
      campo: 'cadastradoEcuro',
      cabecalho: 'Cad. Ecuro',
      alinhamento: 'left',
      format: ( v ) => v ? 'Sim' : 'Não'
    }
  ]

  const ResetDados: ClienteInterface = {
    idCliente: 0,
    idClinica: 0,
    cpf: '',
    nome: '',
    whatsAPP: '',
    dataNascimento: '',
    email: '',
    fone: '',
    idEcuroPaciente: '',
    idEcuroClinica: '',
    si_pagamento: 0,
    si_saldo: 0,
    si_aprovado: 0,
    si_executado: 0,
    si_oportunidade: 0,
    sa_pagamento: 0,
    sa_saldo: 0,
    sa_aprovado: 0,
    sa_executado: 0,
    sa_oportunidade: 0,
    dataAtualizacaoSaldo: '',
    idUsuarioCadastro: 0,
    idUsuarioUltimoAgendamento: 0,
    clienteBaseEcuro: false
  }

  const ResetDadosDadosAgendamentoClienteCRMInput: AgendamentoClienteCRMInputInterface = {
    data: '',
    horario: '',
    idCliente: 0,
    idClinica: 0,
    idDentista: 0,
    idEcuroEspecialidade: ''
  }

  const TituloForm = {
    [StatusForm.Incluindo]: 'Inclusão de Novo Cliente',
    [StatusForm.Excluindo]: 'Exclusão de Cliente',
    [StatusForm.Pesquisando]: 'Clientes disponívels no APP',
    [StatusForm.Editando]: 'Alteração de Dados de Clientes',
    [StatusForm.EditandoAgenda]: 'Agendamento de Horários'
  }

  const [dados, setDados] = useState<ClienteInterface>( ResetDados )

  const [rsDadosAgendamentoClienteCRMInput, setRsDadosAgendamentoClienteCRMInput] = useState<AgendamentoClienteCRMInputInterface>( ResetDadosDadosAgendamentoClienteCRMInput )

  const [rsDentistasDisponiveis, setRsDentistasDisponiveis] = useState<Array<rsDisponibilidadeDentistaInterface>>( [] )

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface

  const { mensagemState, setMensagemState } = contexto

  const [erros, setErros] = useState( {} )

  const [errosPesquisa, setErrosPesquisa] = useState( {} )

  const [pesquisa, setPesquisa] = useState<ClientePesquisaInterface>( { cpf: '', fone: '', nome: '' } )

  const [rsClinicas, setRsClinicas] = useState<Array<ClinicaInterface>>( [] )

  const [rsPesquisa, setRsPesquisa] = useState<rsPesquisaClienteInterface>( { app: [], pacienteEcuro: [] } )

  const navigate = useNavigate()

  const clsApi = new BackEndAPI()

  const validarDados = (): boolean => {

    let retorno: boolean = true
    let erros: { [key: string]: string } = {}

    let clsValidacao = new ClsValidacao()

    retorno = clsValidacao.naoVazio( 'nome', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'idClinica', dados, erros, retorno )
    retorno = clsValidacao.eCPF( 'cpf', dados, erros, retorno, true )
    retorno = clsValidacao.eTelefone( 'whatsAPP', dados, erros, retorno )
    retorno = clsValidacao.eTelefone( 'fone', dados, erros, retorno )
    retorno = clsValidacao.eData( 'dataNascimento', dados, erros, retorno )
    retorno = clsValidacao.eEmail( 'email', dados, erros, retorno, true )

    if ( statusForm === StatusForm.Incluindo ) {

      // Validar Agendamento quando for novo cliente...

      retorno = clsValidacao.eData( 'data', rsDadosAgendamentoClienteCRMInput, erros, retorno )
      // retorno = clsValidacao.naoVazio( 'idCliente', rsDadosAgendamentoClienteCRMInput, erros, retorno )
      retorno = clsValidacao.naoVazio( 'idEcuroEspecialidade', rsDadosAgendamentoClienteCRMInput, erros, retorno )
      retorno = clsValidacao.naoVazio( 'idClinica', rsDadosAgendamentoClienteCRMInput, erros, retorno )
      retorno = clsValidacao.naoVazio( 'idDentista', rsDadosAgendamentoClienteCRMInput, erros, retorno )
      retorno = clsValidacao.eHorario( 'horario', rsDadosAgendamentoClienteCRMInput, erros, retorno )

    }

    setErros( erros )

    return retorno

  }

  const clsCrud: ClsCrud<ClienteInterface> = new ClsCrud(
    navigate,
    ResetDados,
    setStatusForm as any,
    setDados,
    setErros,
    mensagemState,
    setMensagemState,
    setRsPesquisa as any,
    contexto,
    validarDados,
    {
      confirmarMutation: 'updateCliente',
      excluirMutation: 'delCliente',
      campoId: 'idCliente',
      camposPesquisa: '{}', // Não Utilizado....
      pesquisaQuery: 'getClientes',
      pesquisaPorId: 'getClientePorId',
      camposPesquisaPorId: '{idCliente idClinica cpf nome whatsAPP fone dataNascimento email idEcuroPaciente idEcuroClinica si_pagamento si_saldo si_aprovado si_executado si_oportunidade sa_pagamento sa_saldo sa_aprovado sa_executado sa_oportunidade dataAtualizacaoSaldo idUsuarioCadastro idUsuarioUltimoAgendamento clienteBaseEcuro}'
    },
    {
      confirmando: 'Atualizando Cliente',
      erroCadastro: 'Erro ao Cadastrar Cliente',
      erroExclusao: 'Erro ao Excluir Cliente',
      erroPesquisa: 'Erro ao Pesquisar Cliente',
      pesquisando: 'Pesquisando Dados de Clientes...',
      sucessoCadastro: 'Cliente Cadastrada com Sucesso!',
      atualizacaoSucesso: 'Cliente Atualizada com Sucesso!',
      tituloConfirmado: 'Confirmado!',
      sucessoExclusao: 'Cliente Excluída com Sucesso...',
      tituloConfirmacaoExclusao: 'Confirma?',
      tituloErroCadastro: 'Erro!',
      tituloErroExclusao: 'Erro!',
      tituloErroPesquisa: 'Erro!',
      excluindo: 'Excluindo Cliente...'
    }
  )

  const abortController: AbortController = new AbortController()

  const pesquisarClientes = () => {

    const clsApi = new BackEndAPI()

    const query = `
      pesquisarClientesNoAppEEcuro(pesquisa: ${clsUtils.ConverterEmGql( pesquisa )}) {
        app {
          idCliente
          nome
          cpf
          whatsAPP
          fone
          dataNascimento
          email
          Clinica {
            idClinica
            descricao
          }
          idEcuroPaciente
          idClinica
          idUsuarioCadastro
          idUsuarioUltimoAgendamento
          si_pagamento
          si_saldo
          si_aprovado
          si_executado
          si_oportunidade
          sa_pagamento
          sa_saldo
          sa_aprovado
          sa_executado
          sa_oportunidade
          dataAtualizacaoSaldo
          clienteBaseEcuro
        }
        pacienteEcuro {
          idEcuroPaciente
          idEcuroClinica
          idEcuroPublicId
          nome
          cpf
          fone
          dataNascimento
          saldo {
            pagamentos
            saldo
            aprovado
            executado
            specialityId
            descricao
            oportunidades
          }
        }
      }
    `

    clsApi.query<rsPesquisaClienteInterface>( query, 'pesquisarClientesNoAppEEcuro', 'Pesquisando Clientes No Ecuro e no APP...', contexto, abortController ).then( rsPesquisaClientes => {
      setRsPesquisa( rsPesquisaClientes )
    } )

  }

  const pesquisarClinicas = () => {

    const clsApi = new BackEndAPI()

    const query = `
      getClinicas(pesquisa: "") {
        idClinica
        idEcuroClinica
        descricao
        cidade
      }
    `

    clsApi.query<Array<ClinicaInterface>>( query, 'getClinicas', 'Pesquisando Clínicas...', contexto, abortController ).then( rsClinicas => {

      setRsClinicas( rsClinicas )

    } )

  }

  useEffect( () => {

    pesquisarClinicas()

    // pesquisarClientesEcuro()
    // eslint-disable-next-line
  }, [] )

  const validarPesquisa = (): boolean => {
    let retorno: boolean = true
    let erros: { [key: string]: string } = {}

    let clsValidacao = new ClsValidacao()

    if ( pesquisa.nome.length === 0 && pesquisa.cpf.length === 0 && pesquisa.fone.length === 0 ) {
      erros.nome = 'Forneça ao menos uma informação para pesquisa'
      retorno = false
    } else {
      if ( pesquisa.cpf.length > 0 ) {
        retorno = clsValidacao.eCPF( 'cpf', pesquisa, erros, retorno )
      }
    }

    setErrosPesquisa( erros )

    return retorno
  }

  const somarSaldosEcuro = ( rsEcuro: PacienteEcuroInterface ): rsSaldoInterface => {

    let saldo: rsSaldoInterface = { saldo: 0, pagamentos: 0, aprovado: 0, executado: 0, oportunidades: 0 }

    rsEcuro.saldo.forEach( ( rsSaldo: rsSaldoInterface ) => {
      saldo.saldo += rsSaldo.saldo
      saldo.pagamentos += rsSaldo.pagamentos
      saldo.aprovado += rsSaldo.aprovado
      saldo.executado += rsSaldo.executado
      saldo.oportunidades += rsSaldo.oportunidades
    } )

    return saldo
  }

  const [exibirAvisoClienteExistente, setExibirAvisoClienteExistente] = useState<boolean>( false )
  const [rsClienteExistente, setRsClienteExistente] = useState<ClienteInterface>( ResetDados )

  const clienteExistenteAPPPeloIdEcuroPaciente = ( idEcuroPaciente: string ): Promise<boolean> => {

    const query: string = `
      getClientePorIdEcuroPaciente(idEcuroPaciente: "${idEcuroPaciente}") {
        idCliente
        Clinica {
          descricao
        }
        cpf
        nome
        whatsAPP
        fone
        dataNascimento
      }
    `

    return clsApi.query<ClienteInterface>( query, 'getClientePorIdEcuroPaciente', 'Pesquisando Cliente...', contexto ).then( rsCliente => {
      console.log( 'Rs Cliente...: ', rsCliente )
      if ( !rsCliente ) {
        return false
      } else {
        setRsClienteExistente( rsCliente )
        setExibirAvisoClienteExistente( true )
        return true
      }
    } )

  }

  const btIncluirPacienteDoEcuroNoAPP = ( rsEcuro: PacienteEcuroInterface ) => {

    clienteExistenteAPPPeloIdEcuroPaciente( rsEcuro.idEcuroPaciente ).then( rsClienteExistente => {

      if ( !rsClienteExistente ) {

        let idClinica: number = -1

        // Pesquisa a Clínica do Paciente Selecionado....

        const queryClinica: string = `
          getClinicaPorIdEcuroClinica(idEcuroClinica: "${rsEcuro.idEcuroClinica}") {
            idClinica
            descricao
          }
        `

        clsApi.query<ClinicaInterface>( queryClinica, 'getClinicaPorIdEcuroClinica', 'Pesquisando Clínica...', contexto ).then( rsClinica => {

          idClinica = rsClinica.idClinica ? rsClinica.idClinica : -1

          // Realiza Totalizador de Saldos
          const { saldo, pagamentos, aprovado, executado, oportunidades } = somarSaldosEcuro( rsEcuro )

          // Seta os dados com os valores apurados e clinica
          setDados( {
            ...ResetDados,
            sa_pagamento: pagamentos,
            sa_saldo: saldo,
            sa_aprovado: aprovado,
            sa_executado: executado,
            sa_oportunidade: oportunidades,
            si_pagamento: pagamentos,
            si_saldo: saldo,
            si_aprovado: aprovado,
            si_executado: executado,
            si_oportunidade: oportunidades,
            cpf: clsFormatos.cpf( rsEcuro.cpf || '' ) || '',
            nome: rsEcuro.nome,
            dataNascimento: rsEcuro.dataNascimento || '',
            fone: clsFormatos.telefone( rsEcuro.fone ? rsEcuro.fone.replace( /^\+55/g, '' ).replace( /^\+/g, '' ) : '' ) || '',
            idEcuroClinica: rsEcuro.idEcuroClinica,
            idEcuroPaciente: rsEcuro.idEcuroPaciente,
            idClinica: idClinica,
            email: rsEcuro.email || '',
            clienteBaseEcuro: true
          } )

          setRsDadosAgendamentoClienteCRMInput( { ...ResetDadosDadosAgendamentoClienteCRMInput, idClinica: idClinica } )

          setErros( {} )

          setStatusForm( StatusForm.Incluindo )

        } )

      }

    } )

  }

  const btIncluir = () => {
    clsCrud.btIncluir()
  }

  const btPesquisar = () => {
    if ( validarPesquisa() )
      pesquisarClientes()
  }

  /*
  const alterouCliente = ( v: ClienteEcuroInterface ) => {
    setDados( { ...dados, idEcuroCliente: v.idEcuroCliente, ativo: true, cro: v.cro, idClinica: pesquisa.idClinica, nome: v.nome } )
  }
*/
  const btAgendaCliente = ( rs: ClienteInterface ) => {
    setDados( rs )
    setStatusForm( StatusForm.EditandoAgenda )
  }

  const btCloseAgendamento = () => {
    setDados( ResetDados )
    setStatusForm( StatusForm.Pesquisando )
  }

  const acoesPermitidasCadastroEcuro = (): Array<DataTableAcaoInterface<PacienteEcuroInterface>> => {

    if ( pesquisa.cpf.length === 14 && rsPesquisa.app.length > 0 ) {
      return []
    } else {
      return [{
        icone: 'add_circle',
        toolTip: 'Incluir Cliente no APP',
        onAcionador: ( rs: PacienteEcuroInterface ) => btIncluirPacienteDoEcuroNoAPP( rs ),
        onDisabled: ( rs: PacienteEcuroInterface ) => rsClinicas.findIndex( ( v: ClinicaInterface ) => v.idEcuroClinica === rs.idEcuroClinica ) < 0
      }]
    }

  }

  const btConfirmar = () => {

    if ( validarDados() ) {

      let mutation: string = ''

      if ( statusForm === StatusForm.Incluindo ) {

        mutation = `
          updateCliente(
            dados: ${clsUtils.ConverterEmGql( { ...dados, dataAtualizacaoSaldo: DateTime.local().toFormat( DTFORMAT.BANCO_DATETIME ) } )},
            dadosAgendamento: ${clsUtils.ConverterEmGql( rsDadosAgendamentoClienteCRMInput )}
          ) 
          {
            ok
            mensagem
          }
        `
      } else {

        mutation = `
          updateCliente(
            dados: ${clsUtils.ConverterEmGql( { ...dados, dataAtualizacaoSaldo: DateTime.local().toFormat( DTFORMAT.BANCO_DATETIME ) } )}
          ) 
          {
            ok
            mensagem
          }
        `

      }

      clsApi.mutation<RespostaPadraoInterface>( mutation, 'updateCliente', 'Atualizando Cliente...', contexto ).then( rs => {

        if ( rs.ok ) {
          contexto.setMensagemState( {
            cb: null,
            exibir: true,
            exibirBotao: true,
            mensagem: 'Cliente cadastrado com sucesso!',
            tipo: MensagemTipo.Info,
            titulo: 'Cadastro de Cliente'
          } )

          setDados( ResetDados )
          setStatusForm( StatusForm.Pesquisando )

          pesquisarClientes()

        } else {

          contexto.setMensagemState( {
            cb: null,
            exibir: true,
            exibirBotao: true,
            mensagem: 'Erro ao cadastrar cliente!',
            tipo: MensagemTipo.Error,
            titulo: 'Cadastro de Cliente'
          } )

        }

      } )

    }
  }

  // Pesquisa de Clientes Existentes dentro do APP ou Ecuro no processo de Inclusão....

  const [cpfExistenteEcuro, setCpfExistenteEcuro] = useState<boolean>( false )
  const [foneExistenteEcuro, setFoneExistenteEcuro] = useState<boolean>( false )

  const pesquisarCPFOuFone = ( cpf: string, fone: string ) => {

    if ( statusForm === StatusForm.Incluindo && !dados.clienteBaseEcuro ) {

      const clsApi = new BackEndAPI()

      const query = `
        pesquisarClientesNoAppEEcuro(pesquisa: ${clsUtils.ConverterEmGql( {
        cpf: cpf,
        fone: fone,
        nome: ''
      }
      )}) {
          app {
            idCliente
          }
          pacienteEcuro {
            idEcuroPaciente
          }
        }
      `

      clsApi.query<rsPesquisaClienteInterface>( query, 'pesquisarClientesNoAppEEcuro', 'Pesquisando Clientes...', contexto, abortController ).then( rsPesquisaClientes => {

        let mensagem: string = ''

        if ( rsPesquisaClientes.app.length > 0 ) {
          mensagem = 'Cliente já cadastrado no APP. \n'
        }

        if ( rsPesquisaClientes.pacienteEcuro.length > 0 ) {

          mensagem = mensagem.concat( 'Cliente já cadastrado no Ecuro.' )

          if ( cpf.length > 0 ) {
            setCpfExistenteEcuro( true )
            setErros( { ...erros, cpf: 'CPF Existente no Ecuro...' } )
          }

          if ( fone.length > 0 ) {
            setFoneExistenteEcuro( true )
            setErros( { ...erros, fone: 'Fone Existente no Ecuro...' } )
          }

        } else {

          if ( cpf.length > 0 ) {
            setCpfExistenteEcuro( false )
            setErros( { ...erros, cpf: '' } )
          }

          if ( fone.length > 0 ) {
            setFoneExistenteEcuro( false )
            setErros( { ...erros, fone: '' } )
          }

        }

        if ( mensagem.length > 0 ) {

          setMensagemState( {
            cb: null,
            exibir: true,
            exibirBotao: true,
            mensagem: mensagem,
            tipo: MensagemTipo.Warning,
            titulo: 'Cliente Existente'
          } )
        }

      } )

    }

  }

  const pesquisarCPFExistenteEcuro = ( cpf: string ) => {

    if ( cpf && cpf.length === 14 ) {

      pesquisarCPFOuFone( cpf, '' )

    }

    setDados( { ...dados, cpf: cpf } )

  }

  const pesquisarFoneExistenteEcuro = ( fone: string ) => {

    if ( fone && fone.length >= 14 ) {
      pesquisarCPFOuFone( '', clsFormatos.somenteNumeros( fone ) )
    }

    setDados( { ...dados, fone: fone } )

  }

  const onChangeClinica = ( rs: ClinicaInterface ): void => {

    const idClinica = rs && typeof rs.idClinica === 'number' && rs.idClinica > 0 ? rs.idClinica : 0

    setRsDadosAgendamentoClienteCRMInput( { ...ResetDadosDadosAgendamentoClienteCRMInput, idClinica: idClinica } )

    setDados( { ...dados, idClinica: idClinica } )

  }

  const btCancelar = () => {
    setCpfExistenteEcuro( false )
    setFoneExistenteEcuro( false )
    clsCrud.btCancelar()
  }

  return (
    <>

      <ClientesAvisoClienteExistenteModal rsCliente={rsClienteExistente} exibirAvisoClienteExistente={exibirAvisoClienteExistente} setExibirAvisoClienteExistente={setExibirAvisoClienteExistente} />

      <Container maxWidth="xl" sx={{ mt: 5 }}>

        <Paper variant="outlined" sx={{ padding: 2 }}>
          <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
              <Typography component="h5" variant="h5" align="left">
                Cadastro de Clientes
                <Typography variant="body2" gutterBottom>
                  {TituloForm[statusForm]}
                </Typography>
              </Typography>

              <IconButton onClick={() => clsCrud.btFechar()}>
                <CloseIcon />
              </IconButton>
            </Grid>

            {/*
            <Grid item xs={12}>
            
              <PesquisarTabela<any>
                setState={setPesquisa}
                field='idClinica'
                fieldSet='idClinica'
                label='Clínica'
                dados={pesquisa}
                campoQueryPesquisaID='idClinica'
                campoQueryPesquisa='pesquisa'
                camposRetornoQueryPesquisa='{idClinica, descricao}'
                campoLabelQueryPesquisa='descricao'
                nomeQueryPesquisa='getClinicas'
                nomeQueryPesquisaID='getClinicaPorId'
                mensagemPesquisa='Procurando Clínicas...'
                pesquisarTudoAoIniciar
                erros={errosPesquisa}
                disabled={statusForm !== StatusForm.Pesquisando}
                />
                
                </Grid>
  */}

            <Condicional condicao={statusForm === StatusForm.Pesquisando}>

              <Grid item xs={12}>

                <InputText
                  dados={pesquisa}
                  setState={setPesquisa}
                  field='cpf'
                  label='CPF'
                  mask="cpf"
                  erros={errosPesquisa}
                  maxLength={14}
                  mapKeyPress={[{ key: 'Enter', onKey: btPesquisar }]}
                />

              </Grid>

              <Grid item xs={12}>
                <InputText
                  dados={pesquisa}
                  field='fone'
                  label='Fone'
                  erros={errosPesquisa}
                  type='tel'
                  maxLength={14}
                  setState={setPesquisa}
                  mapKeyPress={[{ key: 'Enter', onKey: btPesquisar }]}
                />
              </Grid>

              <Grid item xs={12}>

                <InputText
                  dados={pesquisa}
                  erros={errosPesquisa}
                  field='nome'
                  label='Nome'
                  tipo='uppercase'
                  setState={setPesquisa}
                  mapKeyPress={[{ key: 'Enter', onKey: btPesquisar }]}
                  maxLength={50}
                />

              </Grid>

              <Grid item xs={12} textAlign='right' sx={{ mt: 3 }}>
                <Button variant='contained' onClick={() => { btIncluir() }} sx={{ mr: 2 }}>Novo Cliente</Button>
                <Button variant='contained' onClick={() => { btPesquisar() }}>Pesquisar</Button>
              </Grid>

              <Grid item xs={12} sm={2} sx={{ mt: { xs: 3, sm: 4.5 }, textAlign: { xs: 'right', sm: 'center' } }}>
              </Grid>

              <Grid item xs={12} sm={2} sx={{ mt: { xs: 3, sm: 4.5 }, textAlign: { xs: 'right', sm: 'center' } }}>
              </Grid>

            </Condicional>

            <Condicional condicao={statusForm !== StatusForm.Pesquisando && statusForm !== StatusForm.EditandoAgenda}>
              {/*

              <Grid item xs={12}>
                <ComboBox
                  campoDescricao='nome'
                  campoID='idEcuroCliente'
                  dados={dados}
                  field='idEcuroCliente'
                  label='Cliente'
                  opcoes={rsClientesEcuro}
                  erros={erros}
                  onChange={( v: ClienteEcuroInterface ) => alterouCliente( v )}
                  disabled={statusForm === StatusForm.Excluindo}
                />
              </Grid>

*/}

              <Grid item xs={12}>

                <PesquisarTabela<ClinicaInterface>
                  field='idClinica'
                  fieldSet='idClinica'
                  label='Clínica'
                  dados={dados}
                  campoQueryPesquisaID='idClinica'
                  campoQueryPesquisa='pesquisa'
                  camposRetornoQueryPesquisa='{idClinica, descricao}'
                  campoLabelQueryPesquisa='descricao'
                  nomeQueryPesquisa='getClinicas'
                  nomeQueryPesquisaID='getClinicaPorId'
                  mensagemPesquisa='Procurando Clínicas...'
                  pesquisarTudoAoIniciar
                  erros={erros}
                  disabled={dados.clienteBaseEcuro || statusForm !== StatusForm.Incluindo}
                  onChange={( rs ) => onChangeClinica( rs )}
                />

              </Grid>


              <Grid item xs={12} md={6} lg={3}>

                <InputText
                  dados={dados}
                  setState={setDados}
                  field='cpf'
                  label='CPF'
                  mask="cpf"
                  erros={erros}
                  maxLength={14}
                  onChange={( v ) => pesquisarCPFExistenteEcuro( v )}
                  disabled={dados.clienteBaseEcuro || statusForm !== StatusForm.Incluindo}
                />

              </Grid>

              <Grid item xs={12} md={6} lg={3} sx={{ pl: { md: 1 } }}>
                <InputText
                  dados={dados}
                  setState={setDados}
                  field='fone'
                  label='Fone'
                  erros={erros}
                  type='tel'
                  mask='tel'
                  onChange={( v ) => pesquisarFoneExistenteEcuro( v )}
                  disabled={dados.clienteBaseEcuro || statusForm === StatusForm.Excluindo}
                />
              </Grid>

              <Grid item xs={12} md={6} sx={{ pl: { lg: 1 } }}>

                <InputText
                  dados={dados}
                  field='nome'
                  label='Nome'
                  tipo='uppercase'
                  setState={setDados}
                  disabled={dados.clienteBaseEcuro || statusForm === StatusForm.Excluindo}
                  erros={erros}
                  maxLength={50}
                />

              </Grid>

              <Grid item xs={12} md={3} sx={{ pl: { md: 1, lg: 0 } }}>
                <InputText
                  dados={dados}
                  setState={setDados}
                  field='whatsAPP'
                  label='Whats App'
                  erros={erros}
                  type='tel'
                  mask='tel'
                  disabled={statusForm === StatusForm.Excluindo}
                />
              </Grid>

              <Grid item xs={12} md={3} sx={{ pl: { md: 1 } }}>
                <InputText
                  dados={dados}
                  type='tel'
                  tipo='date'
                  field='dataNascimento'
                  label='Data Nascimento'
                  setState={setDados}
                  erros={erros}
                  disabled={dados.clienteBaseEcuro || statusForm === StatusForm.Excluindo}
                />
              </Grid>

              <Grid item xs={12} md={6} sx={{ pl: { md: 1 } }}>
                <InputText
                  dados={dados}
                  field='email'
                  label='e-mail'
                  tipo='uppercase'
                  setState={setDados}
                  disabled={dados.clienteBaseEcuro || statusForm === StatusForm.Excluindo}
                  erros={erros}
                  maxLength={255}
                />
              </Grid>

              <Grid item xs={12}>
                <Box
                  sx={{
                    display: { xs: 'column', md: 'flex' },
                    justifyContent: 'space-between',
                    bgcolor: 'background.paper',
                    borderRadius: 1,
                  }}
                >

                  <InputText
                    textAlign='right'
                    dados={dados}
                    field='sa_aprovado'
                    label='Aprovado'
                    tipo='currency'
                    setState={setDados}
                    disabled={true}
                    erros={erros}
                    width='150px'
                  />

                  <InputText
                    textAlign='right'
                    dados={dados}
                    field='sa_executado'
                    label='Executado'
                    tipo='currency'
                    setState={setDados}
                    disabled={true}
                    erros={erros}
                    width='150px'
                  />

                  <InputText
                    textAlign='right'
                    dados={dados}
                    field='sa_oportunidade'
                    label='Oportunidade'
                    tipo='currency'
                    setState={setDados}
                    disabled={true}
                    erros={erros}
                    width='150px'
                  />

                  <InputText
                    textAlign='right'
                    dados={dados}
                    field='sa_pagamento'
                    label='Pagamento'
                    tipo='currency'
                    setState={setDados}
                    disabled={true}
                    erros={erros}
                    width='150px'
                  />

                  <InputText
                    textAlign='right'
                    dados={dados}
                    field='sa_saldo'
                    label='Saldo'
                    tipo='currency'
                    setState={setDados}
                    disabled={true}
                    erros={erros}
                    width='150px'
                  />

                </Box>

              </Grid>

              <Condicional condicao={dados.idClinica !== null && dados.idClinica !== 0 && statusForm === StatusForm.Incluindo}>

                <ClienteAgendamentoForm
                  dados={rsDadosAgendamentoClienteCRMInput}
                  setDados={setRsDadosAgendamentoClienteCRMInput}
                  rsDentistasDisponiveis={rsDentistasDisponiveis}
                  setRsDentistasDisponiveis={setRsDentistasDisponiveis}
                  erros={erros}
                  statusForm={statusForm as any}
                  idClinica={dados.idClinica}
                />

              </Condicional>

              <Grid item xs={12} sx={{ mt: 3 }}>

                <Condicional condicao={statusForm === StatusForm.Excluindo}>
                  <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => clsCrud.btConfirmarExclusao( dados, mensagemState, pesquisa )}>Confirmar</Button>
                </Condicional>

                <Condicional condicao={statusForm !== StatusForm.Excluindo}>
                  <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} disabled={cpfExistenteEcuro || foneExistenteEcuro} onClick={() => btConfirmar()}>Confirmar</Button>
                </Condicional>

                <Button variant='contained' startIcon={<CloseIcon />} sx={{ py: 1 }} onClick={() => btCancelar()}>Cancelar</Button>

              </Grid>

            </Condicional>

            <Condicional condicao={statusForm === StatusForm.Pesquisando}>
              <Grid item xs={12} sx={{ mt: 3 }}>
                <Typography component="h5" variant="h5" align="left">
                  Cadastros do App
                </Typography>

                <DataTable
                  dados={rsPesquisa.app} cabecalho={CabecalhoAPP} acoes={
                    [
                      { icone: 'create', toolTip: 'Editar', onAcionador: clsCrud.btEditar },
                      { icone: 'schedule', toolTip: 'Agenda', onAcionador: btAgendaCliente }
                    ]
                  } />
              </Grid>

              <Grid item xs={12} sx={{ mt: 3 }}>
                <Typography component="h5" variant="h5" align="left">
                  Cadastros do Ecuro
                </Typography>

                <DataTable
                  dados={rsPesquisa.pacienteEcuro}
                  cabecalho={CabecalhoEcuro}
                  acoes={acoesPermitidasCadastroEcuro()}
                  cabecalhoGrupo={CabecalhoAgrupamentoSaldo}
                  campoGrupo='saldo'
                  tituloGrupo='Saldos'
                />
              </Grid>
            </Condicional>

            <Condicional condicao={statusForm === StatusForm.EditandoAgenda}>
              <Grid item xs={12} sx={{ margin: 0, padding: 0, mt: 3 }}>
                <ClienteAgendamentos
                  // dados={dados}
                  rsCliente={{ idCliente: dados.idCliente as number, nome: dados.nome, cpf: dados.cpf, idClinica: dados.idClinica }}
                  onCloseAgendamento={btCloseAgendamento}
                />
              </Grid>
            </Condicional>

            <ExibirJSONDev
              exibir={EMDESENVOLVIMENTO}
              oque={[
                // 'rsPesquisa.pacienteEcuro',
                // rsPesquisa.pacienteEcuro,
                'Dados Cliente',
                dados,
                'Dados Agendamento',
                rsDadosAgendamentoClienteCRMInput,
                'Erros',
                erros
              ]}></ExibirJSONDev>

          </Grid>
        </Paper >

      </Container >
    </>
  )
}