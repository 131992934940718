import { PermissoesInterface } from "../ImportBackend/Interfaces/SistemaModuloPermissaoInterfaces"
import { SISTEMA_PERMISSOES } from "../ImportBackend/types/AcessosDataTypes"
import ClsAcesso from "../Utils/ClsAcesso"

export interface MenuOpcoesInterface {
  descricao: string
  path: string
  icon: string
  filhos: Array<MenuOpcoesInterface>
  modulo: string
  permissao: string
}

// TODO - Incluir Novo Módulo / Link

const MENU: Array<MenuOpcoesInterface> = [
  {
    descricao: 'Cadastros',
    path: '',
    icon: 'topic_rounded',
    modulo: '',
    permissao: '',
    filhos: [
      {
        descricao: 'Dentistas',
        path: '/Dentistas',
        icon: 'supervisor_account',
        filhos: [],
        modulo: SISTEMA_PERMISSOES.DENTISTAS.MODULO,
        permissao: SISTEMA_PERMISSOES.DENTISTAS.PERMISSOES.MANUTENCAO
      },
      /*
      {
        descricao: 'Conta Corrente',
        path: '/ContaCorrente',
        icon: 'monetization_on',
        filhos: [],
        modulo: SISTEMA_PERMISSOES.CONTACORRENTE.MODULO,
        permissao: SISTEMA_PERMISSOES.CONTACORRENTE.PERMISSOES.MANUTENCAO
      },
      {
        descricao: 'Produtos',
        path: '/Produtos',
        icon: 'shopping_cart',
        filhos: [],
        modulo: SISTEMA_PERMISSOES.PRODUTOS.MODULO,
        permissao: SISTEMA_PERMISSOES.PRODUTOS.PERMISSOES.MANUTENCAO
      },
      {
        descricao: 'Regionais',
        path: '/Regionais',
        icon: 'location_city',
        filhos: [],
        modulo: SISTEMA_PERMISSOES.REGIONAIS.MODULO,
        permissao: SISTEMA_PERMISSOES.REGIONAIS.PERMISSOES.MANUTENCAO
      },
      */
      {
        descricao: 'Clínicas',
        path: '/Clinicas',
        icon: 'store',
        filhos: [],
        modulo: SISTEMA_PERMISSOES.CLINICAS.MODULO,
        permissao: SISTEMA_PERMISSOES.CLINICAS.PERMISSOES.MANUTENCAO
      },
      {
        descricao: 'Pacientes',
        path: '/Pacientes',
        // import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
        icon: 'sentiment_satisfied',
        filhos: [],
        modulo: SISTEMA_PERMISSOES.PACIENTES.MODULO,
        permissao: SISTEMA_PERMISSOES.PACIENTES.PERMISSOES.MANUTENCAO
      },
      {
        descricao: 'Feriados Nacionais',
        path: '/FeriadosNacionais',
        icon: 'self_improvement',
        filhos: [],
        modulo: SISTEMA_PERMISSOES.FERIADOS_NACIONAIS.MODULO,
        permissao: SISTEMA_PERMISSOES.FERIADOS_NACIONAIS.PERMISSOES.MANUTENCAO
      },
      {
        descricao: 'Whats APP',
        path: '/SetupWhatsAPP',
        icon: 'phonelink_setup',
        filhos: [],
        modulo: SISTEMA_PERMISSOES.FERIADOS_NACIONAIS.MODULO,
        permissao: SISTEMA_PERMISSOES.FERIADOS_NACIONAIS.PERMISSOES.MANUTENCAO
      },
      {
        descricao: 'Download Ecuro',
        path: '/DownloadEcuro',
        icon: 'download',
        filhos: [],
        modulo: SISTEMA_PERMISSOES.ECURO.MODULO,
        permissao: SISTEMA_PERMISSOES.ECURO.PERMISSOES.DOWNLOAD_MOVIMENTACAO
      },
      {
        descricao: 'Unidades Organizacionais',
        path: '/UnidadesOrganizacionais',
        icon: 'account_balance',
        filhos: [],
        modulo: SISTEMA_PERMISSOES.UNIDADEORGANIZACIONAL.MODULO,
        permissao: SISTEMA_PERMISSOES.UNIDADEORGANIZACIONAL.PERMISSOES.MANUTENCAO
      },
      /*
      {
        descricao: 'Parametros',
        path: '/Parametros',
        icon: 'tune',
        filhos: [],
        modulo: SISTEMA_PERMISSOES.PARAMETROS.MODULO,
        permissao: SISTEMA_PERMISSOES.PARAMETROS.PERMISSOES.MANUTENCAO
      }
        */
    ]
  },
  /*
  {
    descricao: 'Relatórios',
    path: '',
    icon: 'assignment_rounded',
    modulo: '',
    permissao: '',
    filhos: [
      {
        descricao: 'Saldos das Unidades',
        path: '/Saldos',
        icon: 'account_balance_wallet',
        filhos: [],
        modulo: SISTEMA_PERMISSOES.RELATORIOS.MODULO,
        permissao: SISTEMA_PERMISSOES.RELATORIOS.PERMISSOES.SALDOUNIDADE
      },
      {
        descricao: 'Produtividade',
        path: '/relAgendamento',
        icon: 'assessment_rounded',
        filhos: [],
        modulo: SISTEMA_PERMISSOES.RELATORIOS.MODULO,
        permissao: SISTEMA_PERMISSOES.RELATORIOS.PERMISSOES.PRODUTIVIDADE
      },
      {
        descricao: 'Contratos',
        path: '/relContratosEmitidos',
        icon: 'library_books',
        filhos: [],
        modulo: SISTEMA_PERMISSOES.RELATORIOS.MODULO,
        permissao: SISTEMA_PERMISSOES.RELATORIOS.PERMISSOES.CONTRATOS
      },
      {
        descricao: 'Cora',
        path: '/relCoraOnLine',
        icon: 'account_balance',
        filhos: [],
        modulo: SISTEMA_PERMISSOES.RELATORIOS.MODULO,
        permissao: SISTEMA_PERMISSOES.RELATORIOS.PERMISSOES.CORA
      },
      {
        descricao: 'Conta Corrente',
        path: '/relContaCorrente',
        icon: 'monetization_on',
        filhos: [],
        modulo: SISTEMA_PERMISSOES.RELATORIOS.MODULO,
        permissao: SISTEMA_PERMISSOES.RELATORIOS.PERMISSOES.CONTACORRENTE
      },
      {
        descricao: 'Cobrança',
        path: '/relParcelas',
        icon: 'receipt',
        filhos: [],
        modulo: SISTEMA_PERMISSOES.RELATORIOS.MODULO,
        permissao: SISTEMA_PERMISSOES.RELATORIOS.PERMISSOES.PARCELAS
      },
      {
        descricao: 'Movimentação Financeira',
        path: '/relMovimentacaoFinanceira',
        icon: 'compare_arrows',
        filhos: [],
        modulo: SISTEMA_PERMISSOES.RELATORIOS.MODULO,
        permissao: SISTEMA_PERMISSOES.RELATORIOS.PERMISSOES.MOVIMENTACAOFINANCEIRA
      }
    ]
  },
  */
  {
    descricao: 'Sistema',
    path: '',
    icon: 'settings_suggestIcon',
    modulo: '',
    permissao: '',
    filhos: [
      {
        descricao: 'Grupos de Usuários',
        path: '/Grupos',
        icon: 'groups_rounded',
        filhos: [],
        modulo: SISTEMA_PERMISSOES.GRUPOS.MODULO,
        permissao: SISTEMA_PERMISSOES.GRUPOS.PERMISSOES.MANUTENCAO
      },
      {
        descricao: 'Usuários',
        path: '/Usuarios',
        icon: 'person_rounded',
        filhos: [],
        modulo: SISTEMA_PERMISSOES.USUARIOS.MODULO,
        permissao: SISTEMA_PERMISSOES.USUARIOS.PERMISSOES.CADASTRO_USUARIO_CLINICA
      }
    ]
  },
  {
    descricao: 'CRM',
    path: '',
    icon: 'groups',
    modulo: '',
    permissao: '',
    filhos: [
      {
        descricao: 'Cadastro de Clientes',
        path: '/Clientes',
        icon: 'people',
        filhos: [],
        modulo: SISTEMA_PERMISSOES.CLIENTES.MODULO,
        permissao: SISTEMA_PERMISSOES.CLIENTES.PERMISSOES.MANUTENCAO
      },
      {
        descricao: 'Agendamentos',
        path: '/relAgendamento',
        icon: 'assignment_rounded',
        filhos: [],
        modulo: SISTEMA_PERMISSOES.RELATORIOS_CRM.MODULO,
        permissao: SISTEMA_PERMISSOES.RELATORIOS_CRM.PERMISSOES.AGENDAMENTOS
      },
      {
        descricao: 'Faturamento',
        path: '/relFaturamento',
        icon: 'local_atm',
        filhos: [],
        modulo: SISTEMA_PERMISSOES.RELATORIOS_CRM.MODULO,
        permissao: SISTEMA_PERMISSOES.RELATORIOS_CRM.PERMISSOES.AGENDAMENTOS
      }
    ]
  }
]

export default class ClsMenu {

  private retornoMenu: Array<MenuOpcoesInterface> = []

  public constructor( private Permissoes: Array<PermissoesInterface> = [] ) {
    this.retornoMenu = this.limparMenuSemFilhos( this.tratarPermissoes( MENU ) )
  }

  private limparMenuSemFilhos ( menu: Array<MenuOpcoesInterface> ): Array<MenuOpcoesInterface> {
    let retorno: Array<MenuOpcoesInterface> = []

    menu.forEach( ( v ) => {
      if (
        ( v.modulo
          && v.modulo.length > 0
          && v.permissao
          && v.permissao.length > 0 )
        || ( v.modulo
          && v.modulo.length > 0 )
        || ( !v.modulo && !v.permissao )
      ) {
        if ( v.filhos && v.filhos.length > 0 ) {
          v.filhos = this.limparMenuSemFilhos( v.filhos )
        }

        if ( ( v.filhos && v.filhos.length > 0 )
          || v.path.length > 0
        )
          retorno.push( v )
      }
    } )

    return retorno


  }
  private tratarPermissoes ( menu: Array<MenuOpcoesInterface> ): Array<MenuOpcoesInterface> {

    let retorno: Array<MenuOpcoesInterface> = []
    let clsAcesso = new ClsAcesso()

    menu.forEach( ( v ) => {
      if (
        ( v.modulo
          && v.modulo.length > 0
          && v.permissao
          && v.permissao.length > 0
          && clsAcesso.chkAcesso( this.Permissoes, v.modulo, v.permissao ) )
        || ( v.modulo
          && v.modulo.length > 0
          && v.permissao.length === 0
          && clsAcesso.chkAcessoModulo( this.Permissoes, v.modulo ) )
        || ( !v.modulo && !v.permissao )
      ) {
        if ( v.filhos && v.filhos.length > 0 ) {
          v.filhos = this.tratarPermissoes( v.filhos )
        }

        /*
        if ( v.permissao === SISTEMA_PERMISSOES.RELATORIOS.PERMISSOES.SALDOUNIDADE )
          console.log( 'Modulo: ', v.modulo, 'Permissão: ', v.permissao, 'Retorno: ', 'No Menu Será Verdadeiro...' )
*/
        retorno.push( v )
      }
    } )

    return retorno

  }

  public get MenuOpcoes (): Array<MenuOpcoesInterface> {
    return this.retornoMenu
  }

}